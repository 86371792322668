export const convertIsoDateTime = (dateTime) => {
	// Assume the dateTime is in this format: "2022-05-15T19:15:00+0000"
	// replace the T for a space, - for / and trim the +0000 so the date will be ok in older browsers (looking at you Safari 13!)
	const converted = dateTime.replace(/T/g, " ").replace(/-/g, "/").slice(0, dateTime.length - 5)
	try {
		// check if we need to convert first
		const testConversion = new Date(dateTime)
		if (!isNaN(testConversion.getTime())) {
			return dateTime
		}
		return converted
	} catch (error) {
		return converted
	}
}

export const getFormattedDisplayDate = (displayDate, timezone = 'BST') => {
	return new Intl.DateTimeFormat('default', {
		localeMatcher: 'best fit',
		weekday: 'long',
		month: 'long',
		day: 'numeric',
		year: 'numeric',
		timezone: timezone,
	}).format(new Date(displayDate.replace(/-/g, "/")))
}

export const getFormattedDisplayTime = (displayDate, timezone = 'BST') => {
	return new Intl.DateTimeFormat([], {
		localeMatcher: 'best fit',
		hour: 'numeric',
		minute: 'numeric',
		timezone: timezone,
	})
		.format(new Date(displayDate.replace(/-/g, "/")))
}

export const getFormattedDisplayDateWithTimezone = (displayDate, timezoneOffset = 'GMT+01:00') => {

	// 2022 Festival dates are May 13-15 which is BST / GMT+01:00
	const startDate = `${displayDate.replace(/-/g, "/")} ${timezoneOffset}`

	return new Intl.DateTimeFormat([], {
		localeMatcher: 'best fit',
		weekday: 'short',
		year: 'numeric',
		month: 'short',
		day: 'numeric',
		timeZoneName: 'short',
		hour: 'numeric',
		minute: 'numeric'
	})
		.format(new Date(startDate))
}

export const getDefaultDisplayDate = (festivalType, festivalYear = 2022) => {
	// use the 2022 festival dates of May 13th - 15th
	const festivalDates = getFestivalDates(festivalYear).filter(item => {
		if (item[festivalType] === true) {
			const day = item.date.split('-')[2]
			if (day) {
				return day
			}
		}
		return false
	})

	const festivalDays = festivalDates.map(item => {
		return item.date.split('-')[2]
	})

	const currentDate = new Date()

	if (festivalDays.includes(currentDate.getDate()) && currentDate.getMonth() === 4) {
		// make sure the date includes the 0 if the day is < 10
		let day = currentDate.getDate()
		if (day < 10) {
			day = `0${day}`
		}
		return `${festivalYear}-05-${day}`
	}

	// return the first date from the festivalDates for the year and type
	return festivalDates.filter(item => {
		return item[festivalType] === true
	})[0]?.date || '2024-05-10'
}

export const getFestivalDates = (year = 2022) => {
	switch (year) {
		case 2024:
			return [
				// { label: 'Online Events', date: '2022-06-30', main: false, childrens: false, schools: true }, // schools
				{ label: 'Fri 10th', date: '2024-05-10', main: true, childrens: false, schools: false }, // main
				{ label: 'Sat 11th', date: '2024-05-11', main: true, childrens: true, schools: false }, // main + childrens
				{ label: 'Sun 12th', date: '2024-05-12', main: true, childrens: true, schools: false }, // main + childrens
			]
		case 2023:
			return [
				// { label: 'Online Events', date: '2022-06-30', main: false, childrens: false, schools: true }, // schools
				{ label: 'Fri 12th', date: '2023-05-12', main: true, childrens: false, schools: true }, // main + schools
				{ label: 'Sat 13th', date: '2023-05-13', main: true, childrens: true, schools: false }, // main + childrens
				{ label: 'Sun 14th', date: '2023-05-14', main: true, childrens: true, schools: false }, // main + childrens
			]
		case 2022:
		default:
			return [
				// online events ran from 9th may for schools, but ran to end of June so the date was changed to accommodate
				{ label: 'Online Events', date: '2022-06-30', main: false, childrens: false, schools: true }, // schools
				{ label: 'Fri 13th', date: '2022-05-13', main: true, childrens: false, schools: true }, // main + schools
				{ label: 'Sat 14th', date: '2022-05-14', main: true, childrens: true, schools: false }, // main + childrens
				{ label: 'Sun 15th', date: '2022-05-15', main: true, childrens: true, schools: false }, // main + childrens
			]
	}
}

export const getFestivalDatesByType = (festivalType, festivalYear = 2022) => {
	const allDates = getFestivalDates(festivalYear)

	let dates
	switch (festivalType) {
		case 'childrens':
			dates = allDates.filter((date) => {
				return date.childrens
			})
			break
		case 'main':
			dates = allDates.filter((date) => {
				return date.main
			})
			break
		case 'pre':
			dates = allDates.filter((date) => {
				return date.pre
			})
			break
		case 'schools':
			dates = allDates.filter((date) => {
				return date.schools
			})
			break
		default:
			dates = [...allDates]
	}

	return dates
}

export const getCutoffDateForFestival = (festivalYear, festivalType) => {
	// schools events are to be available until June 30th
	if (festivalType === 'schools') {
		return new Date(`${festivalYear}-07-01`)
	}

	// The cutoff for the main and children's festival
	return new Date(`${festivalYear}-06-01`) // this will be midnight by default
}

export const showEvents = () => {
	const startDate = new Date('2024/03/18 09:00:00 GMT+00:00')
	const now = new Date()

	return now.valueOf() >= startDate.valueOf()
}
