import React from 'react'
import { Link } from 'gatsby'

const EventTypeFilter = (props) => {

	const { type, changeHandler, useLinks, festivalYear } = props

	let types = [
		// { type: 'pre', label: 'Pre-Festival', link: `/2021/box-office` },
		{ type: 'main', label: 'Main Festival', link: `/${festivalYear}/events` },
		{ type: 'childrens', label: `Children's Festival`, link: `/${festivalYear}/childrens-festival-events` },
		{ type: 'schools', label: `Schools`, link: `/${festivalYear}/schools-events` }
	]

	if (festivalYear === 2024) {
		// no schools events in 2024
		types = types.filter(item => item.type !== 'schools')
	}

	const buttonStyles = `block text-center p-2 lg:p-4 mr-2 mb-2 border-2 border-bbf-mid-green hover:text-white hover:bg-bbf-mid-green transition-all`
	const buttonFocusStyles = `focus:outline-none focus:ring-4 focus:ring-bbf-mid-green focus:ring-opacity-50`

	return (
		<div id={`eventTypeFilter`} className={`flex items-center justify-center flex-wrap`}>
			{types.map((item, index) => {
				const selectedStyles = type === item.type ? `text-white bg-bbf-mid-green` : `text-bbf-mid-green bg-white`
				if (useLinks) {
					return (
						<Link
							to={`${item.link}#eventTypeFilter`}
							className={`${buttonStyles} ${selectedStyles} ${buttonFocusStyles}`}
							key={index}>{item.label}</Link>
					)
				}
				return (
					<button
						type={`button`}
						className={`${buttonStyles} ${selectedStyles} ${buttonFocusStyles}`}
						key={index}
						onClick={() => changeHandler(item.type)}
					>{item.label}</button>
				)
			})}
		</div>
	)
}

export default EventTypeFilter
