import React from 'react'

const EventDayFilter = (props) => {

	const { getDates, changeHandler, selectedDate, type, showAllEventsOption, festivalYear } = props

	const dates = getDates(type, festivalYear)

	const EventDayButton = (props) => {
		const { day } = props
		return (
			<button
				type={`button`}
				className={`p-2 lg:p-4 mr-2 mb-2 border-2 border-bbf-light-blue mr-2 hover:text-white hover:bg-bbf-light-blue transition-all focus:outline-none focus:ring-4 focus:ring-bbf-light-blue focus:ring-opacity-50 ${selectedDate === day.date ? `bg-bbf-light-blue text-white` : `bg-white text-bbf-light-blue`}`}
				onClick={() => changeHandler(day.date)}
			>{day.label}</button>
		)
	}

	return (
		<div className={`flex items-center justify-center flex-wrap`}>
			{dates.map((day, index) => {
				return <EventDayButton day={day} key={day.date} />
			})}
			{ showAllEventsOption && <EventDayButton day={{date: 'all', label: 'All Events'}} /> }
		</div>
	)
}

export default EventDayFilter
