import React, { useState, useEffect, useCallback } from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
// import PaypalDonateFormCard from '../Form/PaypalDonateFormCard'
import EventDayFilter from '../Event/EventDayFilter'
import EventTypeFilter from '../Event/EventTypeFilter'
import { getDefaultDisplayDate, getFestivalDatesByType } from '../../utils/dates'

const BoxOffice = (props) => {

	const data = useStaticQuery(graphql`{
		site {
			siteMetadata {
				config {
					boxOfficeBaseUrl
					boxOfficeEnabled
					env
				}
			}
		}
	}`)

	const { boxOfficeEnabled, boxOfficeBaseUrl, env } = data.site.siteMetadata.config

	// this component relies on the location object (provided to pages by gatsby)
	// the location object is pretty much identical to window.location, except for the state parameter
	// pageType should be 'main', 'childrens' or 'schools'
	const { pageLocation, pageType, showDayFilter, showTypeFilter } = props

	const initialDisplayDate = pageLocation.state && pageLocation.state.date ? pageLocation.state.date : getDefaultDisplayDate()
	const initialFestivalType = pageLocation.state && pageLocation.state.festivalType ? pageLocation.state.festivalType : pageType
	const initialUrlParams = pageLocation.search.length ? pageLocation.search : ''

	const [pageUrlParams, setPageUrlParams] = useState(initialUrlParams)
	const [displayDate, setDisplayDate] = useState(initialDisplayDate)
	const [festivalType, setFestivalType] = useState(initialFestivalType)
	const [ftEventId, setFtEventId] = useState(null)
	const [ftParams, setFtParams] = useState(``)
	const [ftEmbedURL, setFTEmbedURL] = useState(null)

	const getFTEmbedURL = useCallback(() => {
		const cacheBuster = new Date().getTime()
		let params = `?${cacheBuster}`
		if (ftParams.length) {
			params = `${ftParams}&${cacheBuster}`
		}
		if (env === 'development') {
			return `${boxOfficeBaseUrl}/box-office-${props.festivalYear}-${festivalType}.html${params}`
		}
		return `https://www.boswellbookfestival.co.uk/box-office-${props.festivalYear}-${festivalType}.html${params}`
	}, [displayDate, festivalType, ftParams])

	const getBackLink = () => {

		const festivalYear = props.festivalYear || 2023

		const link = {
			label: 'Back to events',
			href: `/${festivalYear}/events`
		}

		if (pageLocation.state?.eventCategory) {
			switch (pageLocation.state?.eventCategory) {
				case 'childrens':
					link.href = `/${festivalYear}/childrens-festival-events`
					break
				case 'schools':
					link.href = `/${festivalYear}/schools-events`
					break
				default:
					break
			}
		}

		return link

	}

	useEffect(() => {

		// check the url params for the date in case we have a redirect
		if (pageUrlParams.length) {

			const params = new URLSearchParams(pageUrlParams)

			if (params.has('date')) {
				if (displayDate !== params.get('date')) {
					setDisplayDate(params.get('date'))
				}
			}

			if (params.has('type')) {
				if (festivalType !== params.get('type')) {
					// keep it as 'rerun' so we keep the same box office display
					// setFestivalType(params.get('type'))
				}
			}

			let ftParamString = ``
			if (params.has('ftredirect')) {
				ftParamString = `?ftredirect=1`
			}

			if (params.has('eventid')) {
				setFtEventId(params.get('eventid'))
				if (ftParamString.length === 0) {
					ftParamString = `?eventid=${params.get('eventid')}`
				} else {
					ftParamString += `&eventid=${params.get('eventid')}`
				}
			}

			if (ftParams !== ftParamString) {
				setFtParams(ftParamString)
			}
		}

		if (festivalType === 'pre') {
			setDisplayDate('2021-05-16')
		}

		if (festivalType === 'rerun') {
			// TODO: Change date to 2021-10-29 before launch of October 2021 rerun
			setDisplayDate('2021-06-11')
		}

		setFTEmbedURL(getFTEmbedURL())

	},  [displayDate, festivalType, ftEventId, ftParams, getFTEmbedURL, pageUrlParams])

	// const boxOfficeUrl = `${baseUrl}/box-office-${displayDate}${festivalType === 'childrens' ? `-childrens` : ``}.html${ftParams}`
	// const boxOfficeUrl = buildPageUrl(displayDate, festivalType, ftParams)

	const handleDayChange = (newDate) => {
		if (newDate !== displayDate) {
			setDisplayDate(newDate)
			handleResetUrl()
			setFTEmbedURL(getFTEmbedURL())
		}
	}

	const handleTypeChange = (newType) => {
		if (newType !== festivalType) {
			setFestivalType(newType)
			checkDateAvailable(newType)
		}
	}

	const checkDateAvailable = (type) => {
		const datesForType = getFestivalDatesByType(type)
		const selectedDateCheck = datesForType.filter(day => {
			return displayDate === day.date
		})

		if (selectedDateCheck.length === 0) {
			setDisplayDate(datesForType[0].date)
		}

		handleResetUrl()
		setFTEmbedURL(getFTEmbedURL())
	}

	const handleResetUrl = () => {
		setPageUrlParams(``)

		const params = new URLSearchParams(initialUrlParams)
		let search = ''

		// retain the ftredirect so iOS folks don't keep having to tap the button to see events
		if (params.has('ftredirect')) {
			search = `?ftredirect=1`
			// add the event id in
			if (params.has('eventid')) {
				search += `&eventid=${params.get('eventid')}`
			}
			setFtParams(search)
		} else {
			setFtParams(``)
		}
		setFtEventId(null)
	}

	return boxOfficeEnabled ? (
		<section className="flex flex-row items-start flex-wrap pt-4 pb-16 bg-boswell-handwriting">
			<div className={`p-4 md:p-12 w-full`}>
				<div className={`md:flex md:items-start mb-12`}>
					<div className={`pb-8 md:pb-0 md:pr-12`}>
						{/*<h2 className={`text-3xl mb-6 font-bold`}>Box Office for the 2021 Boswell Book Festival {festivalType === 'childrens' && `Children's Festival`}</h2>*/}
						{/*<h3 className={`text-2xl mb-6`}>Tickets for online events at this years Festival are free of charge. Please register below and you will be sent an email with links to all the events. Viewing open from 9:00am on Friday 29 October.</h3>*/}
						{/*<h4 className={`text-xl mb-6`}>There is a single registration that gives access to all main festival events except the new Frances Wilson talk on DH Lawrence for which you need to register separately.</h4>*/}
						{/*<h4 className={`text-xl mb-6`}>There is also a single registration for all School and Family festival events. Viewing for School and Family events will be open from 9.00am on Monday 18 October.</h4>*/}
						{/*<p>You can register for more than one event at a time by adding them to your basket.</p>*/}
						{/*<p>After completing registration, check your email including your junk folder!</p>*/}
						<p className={`text-lg`}>After completing your purchase, check for your email confirmation (don’t forget to check your junk folder).</p>
						<p className={`text-lg mb-4`}>To buy tickets, type number required in box on right.</p>

						{getBackLink().href.length && (
							<Link to={getBackLink().href} className={`link text-xl`}>{getBackLink().label}</Link>
						)}
						<p className={`mt-8 mb-2 text-sm font-semibold`}>Having trouble booking? We have seen occasional problems booking on iPhone and iPad. If possible, please try an alternative device.</p>
						<p className={`text-sm font-bold`}>Tickets are available over the phone by calling <a className={`link`} href="tel:0333 0035 077">0333 0035 077</a>.  Lines are open Mon - Sat, 09:00 - 18:00 until Friday 10 May.</p>
					</div>
					{/*<PaypalDonateFormCard*/}
					{/*	title={`Festival events`}*/}
					{/*	initialValue={10}*/}
					{/*	valueText={`£10`}*/}
					{/*	minValue={5}*/}
					{/*	maxValue={1000000}*/}
					{/*	description={(*/}
					{/*		<>*/}
					{/*			<p>Rather than charge for talks, we will rely on your generosity to help finance the Festival which is run by a charity.</p>*/}
					{/*			<p>Please donate as much as you can.</p>*/}
					{/*		</>*/}
					{/*	)}*/}
					{/*	headerClassName={`bg-bbf-yellow`}*/}
					{/*/>*/}
				</div>
				{ftEventId && (
					<p className={`mb-2 md:text-right`}>
						{/* Commented out and replaced for the October 2021 rerun */}
						{/* <a
							href={`/2021/${pageType !== 'main' ? `${pageType}-festival-` : `` }box-office?date=${displayDate}`}
							title={`Click the link to show all events on ${getFormattedDisplayDate()}`}
							className={`link`}
						>View all events on {getFormattedDisplayDate()}</a> */}

						{/* TODO: update this date to 2021-10-29 when the time comes */}
						{/*<a*/}
						{/*	href={`/2021/${pageType !== 'main' ? `${pageType}-festival-` : `` }box-office?date=2021-06-11`}*/}
						{/*	title={`Click the link to show all events on ${getFormattedDisplayDate(displayDate)}`}*/}
						{/*	className={`link`}*/}
						{/*>View all events</a>*/}
					</p>
				)}
				<div className={`flex flex-col items-center`}>
					{/* Filters are commented out for the October 2021 rerun  */}
					{ showTypeFilter && <EventTypeFilter changeHandler={handleTypeChange} type={festivalType} /> }
					{ showDayFilter && <EventDayFilter changeHandler={handleDayChange} getDates={getFestivalDatesByType} selectedDate={displayDate} type={festivalType} /> }
				</div>
				<section className={`iframe-container mx-auto ${props.homepage ? 'homepage' : ''}`}>
					<iframe title={`Future Ticketing Embed`} src={ftEmbedURL} />
				</section>
			</div>
		</section>
	) : (
		<section className="flex flex-row items-start flex-wrap pt-4 pb-16 bg-boswell-handwriting">
			<div className={`p-4 md:p-12`}>
				<h2 className={`text-2xl mb-4`}>The Box Office is closed.</h2>
				<p>
					{getBackLink().href.length && (
						<Link to={getBackLink().href} className={`link text-xl`}>{getBackLink().label}</Link>
					)}
				</p>
			</div>
		</section>
	)
}

export default BoxOffice
